/*------------------------------------*\
  $COOKIES
\*------------------------------------*/

$banner-background-color: #000;
$banner-text-color: #fff;
$banner-link-color: #fff;
$banner-btn-background-color: #fff;
$banner-btn-text-color: #000;

.cookie-banner {
  padding: 15px 0;
  background: {
    color: $banner-background-color;
  }

  .wrap {
    overflow: hidden;
  }

  p {
    margin-bottom: 10px;
    line-height: 1.3;
    color: $banner-text-color;
    font-size: font-size(15);

    @include mq(mq600) {
      @include span(14 of 16);
      margin: 0;
    }
  }

  a {
    color: $banner-link-color;
  }

  .btn {
    background-color: $banner-btn-background-color;
    color: $banner-btn-text-color;

    @include mq(mq600) {
      float: right;
    }
  }
}
