/*------------------------------------*\
  $MESSAGES
\*------------------------------------*/

.message-box {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid;

  p {
    margin-bottom: 0;
    font-weight: bold;
  }

  &--error {
    color: mix($error-color, $black, 40%);
    background-color: $error-color;
    border-color: mix($error-color, $black, 75%);
  }

  &--warning {
    color: mix($warning-color, $black, 40%);
    background-color: $warning-color;
    border-color: mix($warning-color, $black, 75%);
  }

  &--success {
    color: mix($success-color, $black, 40%);
    background-color: $success-color;
    border-color: mix($success-color, $black, 75%);
  }
}

.message-text {
  font-weight: bold;

  &--error {
    color: darken($error-color, 50%);
  }

  &--warning {
    color: darken($warning-color, 50%);
  }

  &--success {
    color: darken($success-color, 50%);
  }
}
