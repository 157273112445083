/*------------------------------------*\
  $BUTTONS
\*------------------------------------*/

/**
 * The basic structure of a button.
 */
.btn {
  display: inline-block;
  vertical-align: middle;
  margin: 0;

  font: inherit;
  text-align: center;
  text-decoration: none;

  background: {
    color: transparent;
  }

  transition: background-color 0.4s, color 0.4s, border 0.4s;

  cursor: pointer;

  outline: 0;
}


/**
 * Full-width buttons
 */
.btn--full {
  width: 100%;
}


/**
 * A button with slightly rounded corners
 */
.btn--rounded {
  padding: 14px 25px;

  font: {
    size: font-size(17.5);
  }
  color: $white;

  border-radius: 10px;
  border: 1px solid $white;

  @include states {
    color: rgba($white, 0.75);
    border: 1px solid rgba($white, 0.75);
  }
}

.btn--purple {
  color: $white;
  background: {
    color: $brand;
  }

  &.btn--proceed {
    background: {
      image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.48 25.92"><path d="M3.78,25.92l8.7-13L3.72,0,0,2.46,7.08,13l-7,10.5Z" fill="#ffffff"/></svg>');
    }
  }

  @include states {
    color: $white;
    background: {
      color: darken($brand, 5%);
    }
  }
}

.btn--white {
  color: $brand;
  background: {
    color: $white;
  }

  &.btn--proceed {
    background: {
      image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.48 25.92"><path d="M3.78,25.92l8.7-13L3.72,0,0,2.46,7.08,13l-7,10.5Z" fill="#ffffff"/></svg>');
    }
  }

  @include states {
    color: $brand;
    background: {
      color: darken($white, 5%);
    }
  }
}

.btn--turquoise {
  color: $white;
  background: {
    color: #007279;
  }

  &.btn--proceed {
    background: {
      image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.48 25.92"><path d="M3.78,25.92l8.7-13L3.72,0,0,2.46,7.08,13l-7,10.5Z" fill="#ffffff"/></svg>');
    }
  }

  @include states {
    color: $white;
    background: {
      color: darken(#007279, 5%);
    }
  }
}

.btn--green {
  color: $white;
  background: {
    color: #8eb31d;
  }

  &.btn--proceed {
    background: {
      image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.48 25.92"><path d="M3.78,25.92l8.7-13L3.72,0,0,2.46,7.08,13l-7,10.5Z" fill="#ffffff"/></svg>');
    }
  }

  @include states {
    color: $white;
    background: {
      color: darken(#8eb31d, 5%);
    }
  }
}

.btn--outline {
  border: 1px solid #424242 !important;
  color: #424242;

  @include states {
    color: #424242;
  }
}

.btn--proceed {
  padding-right: 50px;
  background: {
    image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.48 25.92"><path d="M3.78,25.92l8.7-13L3.72,0,0,2.46,7.08,13l-7,10.5Z" fill="#424242"/></svg>');
    repeat: no-repeat;
    position: center right 30px;
    size: 7px 13px;
  }

  transition: background-position 0.4s;

  @include states {
    background: {
      position: center right 25px;
    }
  }
}

.btn--share {
  padding: {
    left: 50px;
  }
  color: #747474;
  background: {
    color: #f4f4f4;
    image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 79.95 96.45"><path d="M79.95,15.4A15.08,15.08,0,0,0,77.4,6.8a19.92,19.92,0,0,0-2.15-2.4A14.3,14.3,0,0,0,64.6,0a15.52,15.52,0,0,0-11,4.4,15.47,15.47,0,0,0-4.4,11,14.81,14.81,0,0,0,.8,4.9,0.76,0.76,0,0,0-.4.2L25.9,36.3a3.6,3.6,0,0,0-.55.45,14.19,14.19,0,0,0-10-3.9A15.23,15.23,0,0,0,4.4,37.35,15.07,15.07,0,0,0,0,48.2,14.73,14.73,0,0,0,4.4,59a14.9,14.9,0,0,0,10.95,4.7,14,14,0,0,0,9.9-3.9q0.3,0.25.65,0.5L49.6,76l0.35,0.25a16.57,16.57,0,0,0-.7,4.85,14.61,14.61,0,0,0,4.4,10.65,15,15,0,0,0,11,4.7,13.86,13.86,0,0,0,10.65-4.7,14.8,14.8,0,0,0,2.15-2.2,14.53,14.53,0,0,0,2.55-8.45,14.85,14.85,0,0,0-2.55-8.55,14.15,14.15,0,0,0-2.15-2.35A14.22,14.22,0,0,0,64.6,65.75,15.31,15.31,0,0,0,54.45,69.5a1,1,0,0,0-.35-0.3L30.4,53.45q-0.15-.15-0.45-0.4a15.63,15.63,0,0,0,.7-4.85,16.57,16.57,0,0,0-.7-4.85,3.85,3.85,0,0,1,.45-0.2l23.8-15.8A2.32,2.32,0,0,1,54.65,27a14.92,14.92,0,0,0,10,3.7A13.86,13.86,0,0,0,75.25,26a16.36,16.36,0,0,0,2.15-2.15A14.53,14.53,0,0,0,79.95,15.4Z" fill="#aaaaaa"/></svg>');
    size: 15px 18px;
    repeat: no-repeat;
    position: left 20px center;
  }

  cursor: pointer;

  @include states {
    color: #f4f4f4;
    background: {
      color: #747474;
    }
  }
}

/**
 * A pill-like button
 */
.btn--pill {
  font: {
    size: font-size(15);
  }
  border-radius: 60px;
  border: 0;

  @include states {
    border: 0;
  }
}


/**
 * A disc shaped button
 */
.btn--disc {
  position: relative;

  padding: 0;
  border: 0;

  width: 45px;
  height: 45px;

  background: {
    color: #f4f4f4;
  }

  border-radius: 50px;

  transition: background-color 0.4s;

  @include states {
    background: {
      color: $brand;
    }

    svg {
      fill: $white;
    }
  }

  svg {
    @include centrally-align;
    position: absolute;

    height: 17px;
    width: 15px;

    fill: rgba($black, 0.3);

    transition: fill 0.4s;
  }
}
