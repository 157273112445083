/*------------------------------------*\
  $SITE NAVIGATION
\*------------------------------------*/

$nav-background-color: #f4f4f4;

.site-navigation {
  display: none;

  position: relative;
  background: {
    color: $nav-background-color;
  }

  @include mq(mq960) {
    display: block;
  }

  .wrap {
    overflow: hidden;

    @include mq($until: mq960) {
      max-width: 100%;
    }
  }
}

.site-navigation__item {
  display: inline-block;
  float: left;

  @include mq($until: mq960) {
    display: block;
    width: 100%;
  }

  @include states {
    > a {
      color: $white;
      background: {
        color: $brand;
      }
    }

    @include mq(mq960) {
      .site-navigation__sub-menu {
        display: block;
      }
    }
  }

  > a {
    padding: 19.5px 15px;
    float: left;

    font: {
      size: font-size(17.5);
    }
    color: #6d6d6d;
    text-transform: uppercase;
    text-decoration: none;

    @include mq($until: mq960) {
      width: 100%;
    }

    @include mq(mq1200) {
      padding: {
        left: 20px;
        right: 20px;
      }
    }

    @include states {
      color: $white;
      background: {
        color: $brand;
      }
    }
  }
}


.site-navigation__sub-menu {
  display: none;

  position: absolute;
  top: 55px;
  right: 0;
  left: 0;
  z-index: 99;

  padding: 60px 0;

  background: $brand;
}

.sub-menu__intro {
  overflow: hidden;

  margin: {
    bottom: 40px;
  }
  padding: {
    bottom: 40px;
  }

  border-bottom: 1px solid rgba($white, 0.25);

  .btn {
    margin-top: 20px;

    @include mq(mq768) {
      margin: {
        top: 0;
        left: 30px;
      }
      margin-left: 30px;
    }
  }
}

.sub-menu__title {
  margin: {
    bottom: 20px;
  }

  font: {
    size: font-size(25);
  }
  color: $white;
}

.sub-menu__description {
  margin: {
    bottom: 0;
  }

  font: {
    size: font-size(15);
  }
  line-height: 1.4;
  color: rgba($white, 0.75);

  @include mq(mq768) {
    @include span(10 of 16);
  }
}

.sub-menu__course-list {
  li {
    display: inline-block;

    margin: {
      right: 20px;
      bottom: 20px;
    }
  }

  .btn {
    border-color: rgba($white, 0.25);

    @include states {
      color: $white;
      border-color: $white;
    }
  }
}
