/*------------------------------------*\
  $EMBEDS

  <figure class="embed embed--responsive">
    <iframe src="..."></iframe>
  </figure>
\*------------------------------------*/

.embed {
  margin-bottom: $base-spacing-unit;
}

.embed--responsive {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  max-width: 100%;
  overflow: hidden;

  iframe {
    @include position(absolute, 0, false, false, 0);
    width: 100%;
    height: 100%;
  }
}
