/*------------------------------------*\
  $ARTICLE
\*------------------------------------*/

.article {
  color: #424242;

  @include mq(mq960) {
    @include span(last 10.5 of 16);
    margin: {
      bottom: 50px;
    }
    padding: {
      right: 60px;
    }

    .page-template-template-courses &,
    .page-template-template-testimonials & {
      @include span(16 of 16);

      padding: {
        right: 0;
      }
    }
  }

  .page-template-template-courses &,
  .page-template-template-testimonials & {
    text-align: center;
  }

  &__title {
    margin: {
      bottom: 35px;
    }
    font: {
      size: font-size(25);
    }
    color: #424242;
  }

  &__content {

    &--centered {
      width: 90%;
      margin: 0 auto;

      @include mq(mq768) {
        width: 70%;
      }
    }

  }

  p {
    font: {
      size: font-size(17.5);
    }
    line-height: 1.6;
    color: #747474;

    &.intro {
      margin: {
        bottom: 20px;
      }
      font: {
        size: font-size(20);
      }
      line-height: 1.5;
      color: #565656;
    }
  }

  li {
    font: {
      size: font-size(17.5);
    }
    line-height: 1.6;
    color: #747474;
  }

  .btn--share {
    margin: {
      top: 30px;
    }
  }
}

.breadcrumbs {
  display: none;
  margin: {
    bottom: 35px;
  }
  font: {
    size: font-size(15);
  }
  color: #747474;

  @include mq(mq768) {
    display: block;
  }

  .page-template-template-courses &,
  .page-template-template-testimonials & {
    text-align: center;
  }

  a {
    color: inherit;
  }
}
