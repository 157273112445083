/*------------------------------------*\
  $GRID
\*------------------------------------*/

@import '../vendor/susy/sass/susy';

$susy:(
  columns: 16,
  gutters: (1 / 6),
  math: fluid,
  output: float,
  gutter-position: after
);
