@charset "UTF-8";
/*------------------------------------*\
	$MAIN
\*------------------------------------*/
/*------------------------------------*\
  $VARS
\*------------------------------------*/
/*------------------------------------*\
  $MIXINS
\*------------------------------------*/
/**
 * Link states
 *
 * @include states {
 *   color: blue;
 * }
 */
/**
 * Style all headings at once
 *
 * @include headings {
 *   color: red;
 * }
 */
/**
 * Vertically align an element inside its container
 *
 * .thing {  @include vertically-align; }
 */
/**
 * Horizontally align an element inside its container
 *
 * .thing {  @include horizontally-align; }
 */
/**
 * Centrally align an element inside its container
 *
 * .thing {  @include centrally-align; }
 */
/**
 * Set the position and top, right, bottom, left values
 *
 * .thing { @include position(relative, 0, 1px, 10px, 0); }
 */
/*------------------------------------*\
  $FUNCTIONS
\*------------------------------------*/
/**
 * Quickly get an em value
 */
/**
 * Quickly get a rem value
 */
/**
 * Font sizing in rems
 */
/**
 * Output an image URL
 */
/**
 * Output an optimised SVG data-uri
 */
/**
 * Helper function to replace characters in a string
 */
/*------------------------------------*\
  $MEDIA QUERIES
\*------------------------------------*/
/*------------------------------------*\
  $RESET

  A modified reset based on the work
  of Eric Myer.
  http://meyerweb.com/eric/tools/css/reset/
\*------------------------------------*/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  background: transparent;
}

article, aside, details, figcaption, figure,
footer, header, menu, nav, section, main {
  display: block;
}

body {
  line-height: 1;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/*------------------------------------*\
  $BOX SIZING
\*------------------------------------*/
html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

/*------------------------------------*\
  $UTILITIES
\*------------------------------------*/
.cf::after,
.block--signature .wrap::after {
  content: "";
  display: table;
  clear: both;
}

/*------------------------------------*\
  $ACCESSIBILITY
\*------------------------------------*/
.skip-content {
  position: absolute;
  padding: 1em;
  top: -3.5em;
  left: 2em;
  z-index: 100;
  color: #fff;
}
.skip-content:focus {
  position: absolute;
  top: 0;
  background-color: #000;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  padding: 0;
  height: 1px;
  width: 1px;
  clip: rect(0 0 0 0);
  border: 0;
}

/*------------------------------------*\
  $TYPE
\*------------------------------------*/
/**
 * Headings
 */
h1 {
  font-size: 1.5625rem;
}

h2 {
  font-size: 1.375rem;
}

h3 {
  font-size: 1.1875rem;
}

h4 {
  font-size: 1rem;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 1rem;
}

/**
 * 1. Make sure headings have proportional line-spacing to avoid a single
 *    heading looking like two.
 */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 10px;
  font-family: "Avenir LT W01_65 Medium1475532", sans-serif;
  line-height: 1.3; /* 1 */
}

p + h2,
p + h3,
p + h4,
p + h5,
ul + h2,
ul + h3,
ul + h4,
ul + h5,
ol + h2,
ol + h3,
ol + h4,
ol + h5 {
  margin-top: 40px;
}

/**
 * Paragraphs
 */
p {
  margin-bottom: 15px;
  line-height: 24px;
}

/**
 * The `.intro` class is used to make the introductory test of a page slightly
 * larger than the rest of the page content.
 */
.intro {
  font-size: 1.25rem;
}

strong,
b {
  font-weight: bold;
}

small {
  font-size: 75%;
}

em,
i,
cite {
  font-style: italic;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

del {
  text-decoration: line-through;
}

mark {
  background: #ff0;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

address {
  margin-bottom: 15px;
}

/**
 * Links
 */
a {
  color: #3c2667;
}
a:hover, a:active, a:focus {
  color: #a6a6a6;
  text-decoration: none;
}

/**
 * Blockquotes
 */
q {
  quotes: "“" "”" "‘" "’";
}
q::before {
  content: open-quote;
}
q::after {
  content: close-quote;
}

blockquote {
  margin-bottom: 15px;
}
blockquote p::before {
  content: "“";
}
blockquote p::after {
  content: "”";
}

/*------------------------------------*\
  $IMAGES
\*------------------------------------*/
img {
  margin-bottom: 15px;
  max-width: 100%;
  font-style: italic;
  vertical-align: middle;
}

/**
 * Google Maps breaks if `max-width: 100%` acts upon it; use their selector
 * to remove the effects.
 *
 * If a `width` and/or `height` attribute have been explicitly defined,
 * let’s not make the image fluid.
 */
.gm-style img,
img[width],
img[height] {
  max-width: none;
}

/*------------------------------------*\
  $LISTS
\*------------------------------------*/
ul,
ol {
  margin-bottom: 15px;
  margin-left: 40px;
}

dl {
  margin-bottom: 15px;
}

dt {
  font-weight: bold;
}

dt,
dd {
  margin-bottom: 5px;
}

nav ul {
  margin: 0;
}
ul li {
  list-style: disc;
}

li {
  margin-bottom: 5px;
  font-size: 1rem;
}
nav li {
  margin: 0;
  list-style: none;
}
li > ul,
li > ol {
  margin: 5px 0 0 20px;
}

/*------------------------------------*\
  $TABLES
\*------------------------------------*/
table {
  margin-bottom: 25px;
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 20px;
  text-align: left;
  font-family: "Avenir LT W01_65 Medium1475532", sans-serif;
  font-size: 1rem;
  line-height: 1.3;
}

th {
  font-family: "Avenir LT W01_65 Medium1475532", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  color: #424242;
  border-bottom: 2px solid #ddd;
}

tr:not(:last-of-type) {
  border-bottom: 1px solid #ddd;
}

td,
tr {
  color: #747474;
}

/*------------------------------------*\
  $FORMS
\*------------------------------------*/
fieldset {
  border: 0;
}

legend {
  margin-bottom: 5px;
}

label {
  display: block;
  margin-bottom: 5px;
  cursor: pointer;
}

input {
  display: block;
}

/**
 * 1. Remove the default browser styling from elements
 */
input[type=text],
input[type=email],
input[type=tel],
input[type=search],
input[type=number],
input[type=date],
input[type=url],
input[type=password],
select,
textarea {
  padding: 0 15px;
  width: 100%;
  height: 45px;
  font-family: "Avenir LT W01_65 Medium1475532", sans-serif;
  font-size: 1rem;
  color: #a6a6a6;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 15px;
  -webkit-appearance: none; /* 1 */
  -moz-appearance: none; /* 1 */
}

meter,
progress,
input[type=range] {
  width: 100%;
}

/**
 * It should be clear that the textarea allows for longer prose, the default
 * is a little too small
 */
textarea {
  display: block;
  padding-top: 15px;
  height: 150px;
}

/**
 * Add an arrow to the select menu since we removed the default one with
 * `appearance: none;`
 */
select {
  background-image: url("/images/arrow-down.svg");
  background-repeat: no-repeat;
  background-position: right 0 15px center;
}

/**
 * Give checkboxes and radio buttons some breathing space next to labels
 */
input[type=radio],
input[type=checkbox] {
  margin-right: 5px;
  display: inline-block;
}

/**
 * Remove inner padding in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has
 * padding (and `textfield` appearance).
 */
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

/**
 * 1. Remove the default browser styling from elements
 * 2. Fixed an issue where [type="submit"] gets an additional 2px padding
 *    in Firefox.
 */
input[type=submit],
button {
  -webkit-appearance: none; /* 1 */
  -moz-appearance: none; /* 1 */
}
input[type=submit]::-moz-focus-inner, input[type=submit]::-moz-focus-inner,
button::-moz-focus-inner,
button::-moz-focus-inner {
  border: 0; /* 2 */
  padding: 0; /* 2 */
}

/**
 * `.field` should wrap a label and input
 */
.field {
  margin-bottom: 15px;
}
.field p {
  margin-bottom: 0;
}

/**
 * `.field--choice` contains either radio buttons or checkboxes
 */
.field--choice label:last-child {
  margin-bottom: 0;
}

/**
 * `.field--error` modifies the defaults only when the input errors
 */
.field--error input,
.field--error textarea {
  color: #615959;
  background-color: #f2dede;
  border: 1px solid #963b3b;
}

.wpcf7-form {
  margin-top: 30px;
}
@media (min-width: 48em) {
  .wpcf7-form {
    max-width: 70%;
  }
}
.wpcf7-form textarea {
  border-radius: 15px;
}
.wpcf7-form br {
  display: none;
}
.wpcf7-form span {
  margin-bottom: 15px;
  float: left;
  width: 100%;
}

/*------------------------------------*\
  $PAGE
\*------------------------------------*/
/**
 * 1. Fonts on OSX will look more consistent with other systems that do not
 *    render text using sub-pixel anti-aliasing.
 */
html {
  overflow-y: scroll;
  min-height: 100%;
  text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale; /* 1 */
  -webkit-font-smoothing: antialiased; /* 1 */
  color: #212121;
  background-color: #fff;
}

body {
  font-family: "Avenir LT W01_65 Medium1475532", sans-serif;
  font-size: 1rem;
}

.wrap {
  margin: 0 auto;
  width: 1200px;
  max-width: 90%;
}
@media (min-width: 60em) {
  .wrap {
    max-width: 95%;
  }
}

/*------------------------------------*\
  $GRID
\*------------------------------------*/
/*------------------------------------*\
  $HEADER
\*------------------------------------*/
.site-header {
  height: 75px;
}
@media (max-width: 59.99em) {
  body:not(.home) .site-header {
    border-bottom: 2px solid #f4f4f4;
  }
}
@media (min-width: 48em) {
  .site-header {
    padding: 50px 0;
    height: auto;
  }
}
.site-header .wrap {
  height: 100%;
}

.site-header__logo {
  margin: 0;
  width: 200px;
}
@media (max-width: 47.99em) {
  .site-header__logo {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    filter: blur(0); /* Stops the weird text blurring issue in Chrome */
  }
}
@media (min-width: 48em) {
  .site-header__logo {
    width: 322px;
  }
}

.site-actions {
  display: none;
}
@media (min-width: 37.5em) and (max-width: 47.99em) {
  .site-actions {
    top: 22px;
    position: absolute;
    right: 100px;
  }
}
@media (min-width: 48em) and (max-width: 59.99em) {
  .site-actions {
    position: absolute;
    top: 66px;
    right: 100px;
  }
}
@media (min-width: 37.5em) {
  .site-actions {
    display: block;
    float: right;
    text-align: right;
  }
}

.site-actions__menu-trigger {
  position: absolute;
  top: 0;
  right: 0;
  height: 73px;
  width: 75px;
  text-indent: -9999px;
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 61 43'%3E%3Cpath d='M57.5 36h-54a3.5 3.5 0 0 0 0 7h54a3.5 3.5 0 0 0 0-7zm0-18h-54a3.5 3.5 0 0 0 0 7h54a3.5 3.5 0 0 0 0-7zM3.5 7h54a3.5 3.5 0 0 0 0-7h-54a3.5 3.5 0 0 0 0 7z' fill='%23ccc'/%3E%3C/svg%3E");
  background-size: 21px 15px;
  background-position: center center;
  background-repeat: no-repeat;
  border: 0;
  outline: 0;
  cursor: pointer;
}
@media (min-width: 48em) {
  .site-actions__menu-trigger {
    height: 169px;
  }
}
@media (min-width: 60em) {
  .site-actions__menu-trigger {
    display: none;
  }
}

.site-actions__social {
  display: inline-block;
  margin: 0;
}
@media (min-width: 60em) {
  .site-actions__social {
    margin-top: 7px;
    margin-right: 20px;
    float: left;
  }
}
@media (min-width: 75em) {
  .site-actions__social {
    margin-right: 30px;
  }
}
.site-actions__social li {
  display: inline;
  list-style: none;
  font-size: 0.78125rem;
  text-transform: lowercase;
}
@media (min-width: 60em) {
  .site-actions__social li:not(:last-child) {
    margin-right: 20px;
  }
}
@media (min-width: 60em) {
  .site-actions__social li:not(:last-child) {
    margin-right: 30px;
  }
}
.site-actions__social li span {
  display: none;
}
@media (min-width: 60em) {
  .site-actions__social li span {
    display: inline-block;
  }
}
.site-actions__social svg {
  display: inline-block;
  position: relative;
  top: 2px;
  margin-right: 10px;
  width: 15.5px;
  height: 12.5px;
  fill: #a6a6a6;
}
.site-actions__social a {
  color: #a6a6a6;
  text-decoration: none;
}

.site-actions__email {
  display: none;
  margin-top: 10px;
  font-size: 1.25rem;
  color: #595959;
  text-transform: lowercase;
  text-align: right;
}
@media (min-width: 60em) {
  .site-actions__email {
    display: block;
  }
}
.site-actions__email a {
  color: inherit;
  text-decoration: none;
  border-bottom: 1px solid #595959;
  transition: border-color 0.2s;
}
.site-actions__email a:hover, .site-actions__email a:active, .site-actions__email a:focus {
  border-color: #fff;
}

/*------------------------------------*\
  $SITE NAVIGATION
\*------------------------------------*/
.site-navigation {
  display: none;
  position: relative;
  background-color: #f4f4f4;
}
@media (min-width: 60em) {
  .site-navigation {
    display: block;
  }
}
.site-navigation .wrap {
  overflow: hidden;
}
@media (max-width: 59.99em) {
  .site-navigation .wrap {
    max-width: 100%;
  }
}

.site-navigation__item {
  display: inline-block;
  float: left;
}
@media (max-width: 59.99em) {
  .site-navigation__item {
    display: block;
    width: 100%;
  }
}
.site-navigation__item:hover > a, .site-navigation__item:active > a, .site-navigation__item:focus > a {
  color: #fff;
  background-color: #3c2667;
}
@media (min-width: 60em) {
  .site-navigation__item:hover .site-navigation__sub-menu, .site-navigation__item:active .site-navigation__sub-menu, .site-navigation__item:focus .site-navigation__sub-menu {
    display: block;
  }
}
.site-navigation__item > a {
  padding: 19.5px 15px;
  float: left;
  font-size: 1.09375rem;
  color: #6d6d6d;
  text-transform: uppercase;
  text-decoration: none;
}
@media (max-width: 59.99em) {
  .site-navigation__item > a {
    width: 100%;
  }
}
@media (min-width: 75em) {
  .site-navigation__item > a {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.site-navigation__item > a:hover, .site-navigation__item > a:active, .site-navigation__item > a:focus {
  color: #fff;
  background-color: #3c2667;
}

.site-navigation__sub-menu {
  display: none;
  position: absolute;
  top: 55px;
  right: 0;
  left: 0;
  z-index: 99;
  padding: 60px 0;
  background: #3c2667;
}

.sub-menu__intro {
  overflow: hidden;
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}
.sub-menu__intro .btn {
  margin-top: 20px;
}
@media (min-width: 48em) {
  .sub-menu__intro .btn {
    margin-top: 0;
    margin-left: 30px;
    margin-left: 30px;
  }
}

.sub-menu__title {
  margin-bottom: 20px;
  font-size: 1.5625rem;
  color: #fff;
}

.sub-menu__description {
  margin-bottom: 0;
  font-size: 0.9375rem;
  line-height: 1.4;
  color: rgba(255, 255, 255, 0.75);
}
@media (min-width: 48em) {
  .sub-menu__description {
    width: 62.1621621622%;
    float: left;
    margin-right: 0.9009009009%;
  }
}

.sub-menu__course-list li {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 20px;
}
.sub-menu__course-list .btn {
  border-color: rgba(255, 255, 255, 0.25);
}
.sub-menu__course-list .btn:hover, .sub-menu__course-list .btn:active, .sub-menu__course-list .btn:focus {
  color: #fff;
  border-color: #fff;
}

/*------------------------------------*\
  $MEDIA WINDOW
\*------------------------------------*/
.media-window__wrapper {
  display: block;
  overflow: hidden;
  margin: 0 auto;
  max-width: 100%;
}
@media (min-width: 60em) {
  .media-window__wrapper {
    width: 1500px;
    max-width: none;
  }
}

.media-window {
  overflow: hidden;
  position: relative;
  z-index: -1;
  float: left;
  width: 100%;
  text-align: center;
}
.media-window picture {
  overflow: hidden;
  float: left;
  width: 100%;
}
@media (min-width: 60em) {
  .media-window picture {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    filter: blur(0); /* Stops the weird text blurring issue in Chrome */
    min-width: 1500px;
    height: 410px;
  }
}
.media-window a {
  float: left;
  width: 100%;
}
.media-window img {
  margin: 0;
  width: 100%;
}

/*------------------------------------*\
  $SIDEBAR
\*------------------------------------*/
.sidebar {
  width: 100%;
}
@media (min-width: 60em) {
  .sidebar {
    width: 27.4774774775%;
    float: left;
    margin-right: 0.9009009009%;
  }
}

.widget {
  margin-bottom: 25px;
  padding: 25px;
  background-color: #f4f4f4;
}
.widget--enquiry {
  background-color: #3c2667;
}
.widget--enquiry .widget__title {
  margin-bottom: 15px;
  color: #fff;
}
.widget--enquiry ul {
  margin-left: 0;
  margin-bottom: 40px;
}
.widget--enquiry li {
  opacity: 0.75;
  margin-bottom: 10px;
  list-style: none;
  font-size: 0.9375rem;
  line-height: 1.5;
  color: #fff;
}
.widget--enquiry .btn--proceed {
  color: #fff;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12.48 25.92'%3E%3Cpath d='M3.78,25.92l8.7-13L3.72,0,0,2.46,7.08,13l-7,10.5Z' fill='%23fff'/%3E%3C/svg%3E");
  border: 1px solid #fff;
}
.widget--enquiry .btn--proceed:hover, .widget--enquiry .btn--proceed:active, .widget--enquiry .btn--proceed:focus {
  color: #fff;
  border: 1px solid #fff;
}
.widget__title {
  margin-bottom: 5px;
  font-size: 1.25rem;
  line-height: 1.2;
  color: #424242;
}

/*------------------------------------*\
  $FOOTER
\*------------------------------------*/
.site-footer {
  overflow: hidden;
  position: relative;
  padding: 55px 0;
  background-color: #3c2667;
}
.site-footer .wrap {
  position: relative;
  z-index: 1;
}
.site-footer__section:not(:last-child) {
  margin-bottom: 50px;
}
@media (min-width: 60em) {
  .site-footer__section {
    width: 36.9369369369%;
    float: left;
    margin-right: 0.9009009009%;
  }
  .site-footer__section:last-of-type {
    float: right;
    margin-right: 0;
  }
}
.site-footer__section a {
  color: #c1bdcb;
  text-decoration: none;
}
.site-footer__section a:hover, .site-footer__section a:active, .site-footer__section a:focus {
  text-decoration: underline;
}
.site-footer__section p {
  margin-bottom: 5px;
  font-size: 0.9375rem;
  color: #c1bdcb;
}
.site-footer__section p:last-of-type {
  margin-bottom: 0;
}
.site-footer__section p a {
  text-decoration: underline;
}
.site-footer__section p a:hover, .site-footer__section p a:active, .site-footer__section p a:focus {
  text-decoration: none;
}
.site-footer__title {
  margin-bottom: 30px;
  font-size: 1.25rem;
  color: #fff;
}
.site-footer__list {
  margin-left: 0;
  margin-bottom: 20px;
  column-count: 3;
}
.site-footer__list li {
  margin-bottom: 10px;
  list-style: none;
  font-size: 0.9375rem;
  color: #c1bdcb;
}
.site-footer > svg {
  position: absolute;
  right: -30px;
  bottom: -60px;
  z-index: 0;
  width: 335px;
  transform: rotate(-10deg);
}

.site-credits {
  padding: 35px 0;
}
.site-credits .wrap {
  overflow: hidden;
}
.site-credits p {
  margin-bottom: 0;
  float: left;
  width: 100%;
  font-size: 0.78125rem;
  line-height: 1.5;
  color: #747474;
}
@media (min-width: 48em) {
  .site-credits p {
    width: auto;
  }
}
.site-credits p a {
  color: inherit;
}
.site-credits .btn {
  margin-right: 15px;
  margin-bottom: 15px;
  float: left;
}
@media (min-width: 48em) {
  .site-credits .btn {
    margin-left: 0px;
    margin-bottom: 0;
    float: right;
  }
}

/*------------------------------------*\
  $BUTTONS
\*------------------------------------*/
/**
 * The basic structure of a button.
 */
.btn {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  font: inherit;
  text-align: center;
  text-decoration: none;
  background-color: transparent;
  transition: background-color 0.4s, color 0.4s, border 0.4s;
  cursor: pointer;
  outline: 0;
}

/**
 * Full-width buttons
 */
.btn--full {
  width: 100%;
}

/**
 * A button with slightly rounded corners
 */
.btn--rounded {
  padding: 14px 25px;
  font-size: 1.09375rem;
  color: #fff;
  border-radius: 10px;
  border: 1px solid #fff;
}
.btn--rounded:hover, .btn--rounded:active, .btn--rounded:focus {
  color: rgba(255, 255, 255, 0.75);
  border: 1px solid rgba(255, 255, 255, 0.75);
}

.btn--purple {
  color: #fff;
  background-color: #3c2667;
}
.btn--purple.btn--proceed {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12.48 25.92'%3E%3Cpath d='M3.78,25.92l8.7-13L3.72,0,0,2.46,7.08,13l-7,10.5Z' fill='%23ffffff'/%3E%3C/svg%3E");
}
.btn--purple:hover, .btn--purple:active, .btn--purple:focus {
  color: #fff;
  background-color: #311f54;
}

.btn--white {
  color: #3c2667;
  background-color: #fff;
}
.btn--white.btn--proceed {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12.48 25.92'%3E%3Cpath d='M3.78,25.92l8.7-13L3.72,0,0,2.46,7.08,13l-7,10.5Z' fill='%23ffffff'/%3E%3C/svg%3E");
}
.btn--white:hover, .btn--white:active, .btn--white:focus {
  color: #3c2667;
  background-color: #f2f2f2;
}

.btn--turquoise {
  color: #fff;
  background-color: #007279;
}
.btn--turquoise.btn--proceed {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12.48 25.92'%3E%3Cpath d='M3.78,25.92l8.7-13L3.72,0,0,2.46,7.08,13l-7,10.5Z' fill='%23ffffff'/%3E%3C/svg%3E");
}
.btn--turquoise:hover, .btn--turquoise:active, .btn--turquoise:focus {
  color: #fff;
  background-color: #005a60;
}

.btn--green {
  color: #fff;
  background-color: #8eb31d;
}
.btn--green.btn--proceed {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12.48 25.92'%3E%3Cpath d='M3.78,25.92l8.7-13L3.72,0,0,2.46,7.08,13l-7,10.5Z' fill='%23ffffff'/%3E%3C/svg%3E");
}
.btn--green:hover, .btn--green:active, .btn--green:focus {
  color: #fff;
  background-color: #7d9d19;
}

.btn--outline {
  border: 1px solid #424242 !important;
  color: #424242;
}
.btn--outline:hover, .btn--outline:active, .btn--outline:focus {
  color: #424242;
}

.btn--proceed {
  padding-right: 50px;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12.48 25.92'%3E%3Cpath d='M3.78,25.92l8.7-13L3.72,0,0,2.46,7.08,13l-7,10.5Z' fill='%23424242'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center right 30px;
  background-size: 7px 13px;
  transition: background-position 0.4s;
}
.btn--proceed:hover, .btn--proceed:active, .btn--proceed:focus {
  background-position: center right 25px;
}

.btn--share {
  padding-left: 50px;
  color: #747474;
  background-color: #f4f4f4;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 79.95 96.45'%3E%3Cpath d='M79.95,15.4A15.08,15.08,0,0,0,77.4,6.8a19.92,19.92,0,0,0-2.15-2.4A14.3,14.3,0,0,0,64.6,0a15.52,15.52,0,0,0-11,4.4,15.47,15.47,0,0,0-4.4,11,14.81,14.81,0,0,0,.8,4.9,0.76,0.76,0,0,0-.4.2L25.9,36.3a3.6,3.6,0,0,0-.55.45,14.19,14.19,0,0,0-10-3.9A15.23,15.23,0,0,0,4.4,37.35,15.07,15.07,0,0,0,0,48.2,14.73,14.73,0,0,0,4.4,59a14.9,14.9,0,0,0,10.95,4.7,14,14,0,0,0,9.9-3.9q0.3,0.25.65,0.5L49.6,76l0.35,0.25a16.57,16.57,0,0,0-.7,4.85,14.61,14.61,0,0,0,4.4,10.65,15,15,0,0,0,11,4.7,13.86,13.86,0,0,0,10.65-4.7,14.8,14.8,0,0,0,2.15-2.2,14.53,14.53,0,0,0,2.55-8.45,14.85,14.85,0,0,0-2.55-8.55,14.15,14.15,0,0,0-2.15-2.35A14.22,14.22,0,0,0,64.6,65.75,15.31,15.31,0,0,0,54.45,69.5a1,1,0,0,0-.35-0.3L30.4,53.45q-0.15-.15-0.45-0.4a15.63,15.63,0,0,0,.7-4.85,16.57,16.57,0,0,0-.7-4.85,3.85,3.85,0,0,1,.45-0.2l23.8-15.8A2.32,2.32,0,0,1,54.65,27a14.92,14.92,0,0,0,10,3.7A13.86,13.86,0,0,0,75.25,26a16.36,16.36,0,0,0,2.15-2.15A14.53,14.53,0,0,0,79.95,15.4Z' fill='%23aaaaaa'/%3E%3C/svg%3E");
  background-size: 15px 18px;
  background-repeat: no-repeat;
  background-position: left 20px center;
  cursor: pointer;
}
.btn--share:hover, .btn--share:active, .btn--share:focus {
  color: #f4f4f4;
  background-color: #747474;
}

/**
 * A pill-like button
 */
.btn--pill {
  font-size: 0.9375rem;
  border-radius: 60px;
  border: 0;
}
.btn--pill:hover, .btn--pill:active, .btn--pill:focus {
  border: 0;
}

/**
 * A disc shaped button
 */
.btn--disc {
  position: relative;
  padding: 0;
  border: 0;
  width: 45px;
  height: 45px;
  background-color: #f4f4f4;
  border-radius: 50px;
  transition: background-color 0.4s;
}
.btn--disc:hover, .btn--disc:active, .btn--disc:focus {
  background-color: #3c2667;
}
.btn--disc:hover svg, .btn--disc:active svg, .btn--disc:focus svg {
  fill: #fff;
}
.btn--disc svg {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  filter: blur(0); /* Stops the weird text blurring issue in Chrome */
  position: absolute;
  height: 17px;
  width: 15px;
  fill: rgba(0, 0, 0, 0.3);
  transition: fill 0.4s;
}

/*------------------------------------*\
  $MESSAGES
\*------------------------------------*/
.message-box {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid;
}
.message-box p {
  margin-bottom: 0;
  font-weight: bold;
}
.message-box--error {
  color: #615959;
  background-color: #f2dede;
  border-color: #b6a7a7;
}
.message-box--warning {
  color: #65635b;
  background-color: #fcf8e3;
  border-color: #bdbaaa;
}
.message-box--success {
  color: #596056;
  background-color: #dff0d8;
  border-color: #a7b4a2;
}

.message-text {
  font-weight: bold;
}
.message-text--error {
  color: #963b3b;
}
.message-text--warning {
  color: #caad16;
}
.message-text--success {
  color: #529138;
}

/*------------------------------------*\
  $EMBEDS

  <figure class="embed embed--responsive">
    <iframe src="..."></iframe>
  </figure>
\*------------------------------------*/
.embed {
  margin-bottom: 15px;
}

.embed--responsive {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  max-width: 100%;
  overflow: hidden;
}
.embed--responsive iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/*------------------------------------*\
  $COOKIES
\*------------------------------------*/
.cookie-banner {
  padding: 15px 0;
  background-color: #000;
}
.cookie-banner .wrap {
  overflow: hidden;
}
.cookie-banner p {
  margin-bottom: 10px;
  line-height: 1.3;
  color: #fff;
  font-size: 0.9375rem;
}
@media (min-width: 37.5em) {
  .cookie-banner p {
    width: 87.3873873874%;
    float: left;
    margin-right: 0.9009009009%;
    margin: 0;
  }
}
.cookie-banner a {
  color: #fff;
}
.cookie-banner .btn {
  background-color: #fff;
  color: #000;
}
@media (min-width: 37.5em) {
  .cookie-banner .btn {
    float: right;
  }
}

/*------------------------------------*\
  $SEARCH
\*------------------------------------*/
.search-form {
  display: inline-block;
  width: 205px;
}
@media (min-width: 60em) and (max-width: 74.99em) {
  .search-form {
    width: 175px;
  }
}

.screen-reader-text {
  display: none;
}

input.search-field {
  height: 33.5px;
  font-size: 0.78125rem;
  line-height: 33.5px;
  border-radius: 30px;
}

.search-submit {
  display: none;
}

.search-result:not(:last-child) {
  margin-bottom: 50px;
}
.search-result__title {
  font-size: 1.25rem;
}
.search-result a {
  text-decoration: none;
}

/*------------------------------------*\
  $BLOCKS
\*------------------------------------*/
.block {
  clear: both;
  padding: 85px 0;
  text-align: center;
}
.block__header {
  margin-bottom: 70px;
}
.block__title {
  margin-bottom: 25px;
  padding-left: 5%;
  padding-right: 5%;
  font-size: 1.5625rem;
  line-height: 1.3;
  color: #424242;
}
.block__sub-title {
  margin-right: auto;
  margin-bottom: 0;
  margin-left: auto;
  width: 90%;
  font-size: 1.09375rem;
  line-height: 1.5;
  color: #747474;
}
@media (min-width: 48em) {
  .block__sub-title {
    width: 61%;
  }
}
.block__signature-logo {
  padding: 55px 0;
  text-align: center;
  background-color: #fff;
}
@media (min-width: 48em) {
  .block__signature-logo {
    width: 30.6306306306%;
    float: right;
    margin-right: 0;
  }
}
.block__signature-logo img {
  margin: 0;
  width: 60%;
}

.block--signature {
  padding: 40px 0;
  text-align: left;
  background-color: #008c95;
}
@media (min-width: 48em) {
  .block--signature {
    padding: 85px 0;
  }
}
.block--signature .block__header {
  margin-bottom: 30px;
}
@media (min-width: 48em) {
  .block--signature .block__header {
    width: 68.4684684685%;
    float: left;
    margin-right: 0.9009009009%;
    margin-bottom: 0;
    padding-right: 60px;
  }
}
.block--signature .block__title {
  margin-bottom: 10px;
  padding: 0;
  font-size: 1.40625rem;
  color: #fff;
}
.block--signature .block__sub-title {
  margin-bottom: 40px;
  width: 100%;
  color: #c2ebee;
}
.block--signature .tertiary-link {
  display: block;
  margin-top: 20px;
  color: #c2ebee;
}
@media (min-width: 48em) {
  .block--signature .tertiary-link {
    display: inline-block;
    margin-left: 30px;
    float: none;
    width: auto;
  }
}

.block--testimonials {
  background-color: #f6f9fc;
}
.block--testimonials .block__header {
  margin-bottom: 50px;
}
.block--testimonials .tertiary-link {
  display: block;
  margin-top: 50px;
  color: #87888a;
}

.tertiary-link {
  font-size: 0.78125rem;
}

@media (min-width: 48em) {
  .flex--services {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
.page-template-template-courses .flex--services {
  margin-top: 60px;
  margin-bottom: 40px;
}
.flex--services .box {
  margin-bottom: 20px;
  width: 100%;
}
.flex--services .box .btn--pill {
  margin-top: auto;
}
@media (min-width: 48em) {
  .flex--services .box {
    margin-bottom: 0;
    width: calc(25% - 10px);
  }
}
@media (min-width: 60em) {
  .flex--services .box {
    width: calc(25% - 20px);
  }
}

@media (min-width: 48em) {
  .flex--testimonials {
    display: flex;
    justify-content: space-between;
  }
}
.flex--testimonials .testimonial {
  margin-bottom: 20px;
}
@media (min-width: 48em) {
  .flex--testimonials .testimonial {
    margin-bottom: 0;
    width: calc(50% - 20px);
  }
}

@media (min-width: 37.5em) {
  .flex--updates {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
@media (min-width: 48em) {
  .flex--updates {
    flex-wrap: nowrap;
  }
}
.flex--updates .post-summary:not(:last-child) {
  margin-bottom: 30px;
}
@media (min-width: 37.5em) {
  .flex--updates .post-summary {
    width: calc(50% - 30px);
  }
  .flex--updates .post-summary--tweet {
    margin-top: 30px;
    width: 100%;
  }
}
@media (min-width: 48em) {
  .flex--updates .post-summary {
    margin-bottom: 0;
    width: calc(33.33% - 50px);
  }
  .flex--updates .post-summary--tweet {
    margin-top: 0;
  }
}

.flex--courses {
  margin-top: 50px;
}
@media (min-width: 37.5em) {
  .flex--courses {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
.flex--courses .box {
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 30px;
  text-align: left;
}
@media (min-width: 48em) {
  .flex--courses .box {
    width: calc(50% - 30px);
  }
}

/*------------------------------------*\
  $BOX
\*------------------------------------*/
.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 30px;
  border: 3px solid #eee;
  border-radius: 10px;
  transition: transform 0.4s;
}
.box:hover, .box:active, .box:focus {
  transform: scale(1.01);
}
.box__icon {
  margin-bottom: 35px;
  width: 75px;
  height: 65px;
}
.box__title {
  font-size: 1.25rem !important;
  color: #424242;
}
.box__description {
  margin-bottom: 35px;
  font-size: 0.9375rem !important;
  color: #747474;
}

.box--virtual-classroom {
  background-color: #3c2667;
  color: white;
  max-width: 100%;
  margin: 20px auto 0 auto;
  width: 700px;
  padding: 30px 60px;
}
.box--virtual-classroom .box__title,
.box--virtual-classroom .box__description {
  color: white;
}
.box--virtual-classroom .box__description {
  opacity: 0.75;
}

/*------------------------------------*\
  $TESTIMONIALS
\*------------------------------------*/
.testimonial {
  padding: 40px 30px;
  text-align: left;
  background-color: #fff;
}
.testimonial__title {
  margin-right: 20px;
  float: left;
  font-size: 1.25rem;
}
.testimonial__quote {
  margin: 0;
}
.testimonial__quote p {
  margin-bottom: 0;
  font-size: 0.9375rem;
  color: #747474;
}
.testimonial__course {
  display: inline-block;
  position: relative;
  top: -5px;
  margin-bottom: 10px;
  padding: 10px 15px;
  font-size: 0.625rem;
  color: #424242;
  background-color: #f3f3f3;
  border-radius: 50px;
}
.testimonial blockquote {
  float: left;
}
.testimonial a {
  color: inherit;
  text-decoration: none;
}

/*------------------------------------*\
  $POSTS
\*------------------------------------*/
.post-summary {
  text-align: left;
}
.post-summary--tweet {
  padding: 25px;
  background-color: #f4f4f4;
}
.post-summary__title {
  margin-bottom: 5px;
  font-size: 1.25rem;
  line-height: 1.2;
  color: #424242;
}
.post-summary__title a {
  color: inherit;
  text-decoration: none;
}
.post-summary__title a:hover, .post-summary__title a:active, .post-summary__title a:focus {
  text-decoration: underline;
}
.post-summary__meta {
  margin-bottom: 20px;
  font-size: 0.78125rem;
  line-height: 1.4;
  color: #9e9e9e;
}
.post-summary__excerpt {
  margin-bottom: 20px;
  font-size: 0.9375rem;
  color: #747474;
}
.post-summary__link {
  text-decoration: none;
}
.post-summary__link:hover, .post-summary__link:active, .post-summary__link:focus {
  color: #3c2667;
  text-decoration: underline;
}

/*------------------------------------*\
  $ARTICLE
\*------------------------------------*/
.article {
  color: #424242;
}
@media (min-width: 60em) {
  .article {
    width: 65.3153153153%;
    float: right;
    margin-right: 0;
    margin-bottom: 50px;
    padding-right: 60px;
  }
  .page-template-template-courses .article, .page-template-template-testimonials .article {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0;
    padding-right: 0;
  }
}
.page-template-template-courses .article, .page-template-template-testimonials .article {
  text-align: center;
}
.article__title {
  margin-bottom: 35px;
  font-size: 1.5625rem;
  color: #424242;
}
.article__content--centered {
  width: 90%;
  margin: 0 auto;
}
@media (min-width: 48em) {
  .article__content--centered {
    width: 70%;
  }
}
.article p {
  font-size: 1.09375rem;
  line-height: 1.6;
  color: #747474;
}
.article p.intro {
  margin-bottom: 20px;
  font-size: 1.25rem;
  line-height: 1.5;
  color: #565656;
}
.article li {
  font-size: 1.09375rem;
  line-height: 1.6;
  color: #747474;
}
.article .btn--share {
  margin-top: 30px;
}

.breadcrumbs {
  display: none;
  margin-bottom: 35px;
  font-size: 0.9375rem;
  color: #747474;
}
@media (min-width: 48em) {
  .breadcrumbs {
    display: block;
  }
}
.page-template-template-courses .breadcrumbs, .page-template-template-testimonials .breadcrumbs {
  text-align: center;
}
.breadcrumbs a {
  color: inherit;
}

/*! Flickity v2.2.1
https://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: 0;
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.flickity-button {
  position: absolute;
  background: hsla(0deg, 0%, 100%, 0.75);
  border: none;
  color: #333;
}

.flickity-button:hover {
  background: #fff;
  cursor: pointer;
}

.flickity-button:focus {
  outline: 0;
  box-shadow: 0 0 0 5px #19f;
}

.flickity-button:active {
  opacity: 0.6;
}

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  pointer-events: none;
}

.flickity-button-icon {
  fill: currentColor;
}

.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  transform: translateY(-50%);
}

.flickity-prev-next-button.previous {
  left: 10px;
}

.flickity-prev-next-button.next {
  right: 10px;
}

.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}

.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
}

/*------------------------------------*\
  $TRUMPS
\*------------------------------------*/
.page-content {
  overflow: hidden;
}
body:not(.home) .page-content {
  margin: 35px 0;
}
@media (max-width: 59.99em) {
  .page-content > .wrap {
    display: flex;
    flex-wrap: wrap;
  }
  .page-content > .wrap .sidebar {
    margin-top: 30px;
    order: 2;
  }
}

.overlay {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-animation: fadeIn 0.3s;
}

.modal {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  filter: blur(0); /* Stops the weird text blurring issue in Chrome */
  display: none;
  position: fixed;
  z-index: 99999;
  padding-top: 70px;
  padding-right: 50px;
  padding-bottom: 50px;
  padding-left: 50px;
  width: 580px;
  max-width: 90%;
  text-align: center;
  background-color: #fff;
  border-radius: 15px;
  -webkit-animation: fadeIn 0.3s;
}
.modal__close {
  position: absolute;
  top: 30px;
  right: 30px;
  padding: 0;
  width: 18px;
  height: 18px;
  text-indent: -9999px;
  background-color: transparent;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 90 90'%3E%3Ctitle%3Eicon--close%3C/title%3E%3Cpath d='M50.14 45l38.8-38.8a3.63 3.63 0 0 0-5.14-5.14L45 39.86 6.2 1.07A3.63 3.63 0 0 0 1.06 6.2L39.86 45 1.07 83.8a3.63 3.63 0 1 0 5.13 5.14L45 50.14l38.8 38.8a3.63 3.63 0 1 0 5.14-5.14z' fill='%237f7f7f'/%3E%3C/svg%3E");
  background-size: 18px;
  border: 0;
  outline: 0;
  cursor: pointer;
}
.modal__title {
  font-size: 1.25rem;
  color: #424242;
}
.modal__intro {
  font-size: 0.9375rem;
  line-height: 1.4;
  color: #747474;
}
.modal__social-links {
  margin-top: 25px;
  margin-bottom: 0;
  margin-left: 0;
}
.modal__social-links li {
  display: inline-block;
  list-style: none;
  text-align: center;
}
.modal__social-links li:not(:last-child) {
  margin-right: 10px;
}
.modal form {
  margin-top: 30px;
  margin-right: auto;
  margin-left: auto;
  width: 320px;
  max-width: 100%;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.is-active {
  display: block;
}

.screen-reader-response {
  display: none;
}

.wpcf7-not-valid-tip {
  margin: 10px 0 0 0 !important;
  font-size: 0.75rem;
  color: red;
}

.wpcf7-response-output {
  display: none;
  padding: 15px;
}

.wpcf7-validation-errors {
  color: #615959;
  background-color: #f2dede;
  border: 1px solid #b6a7a7;
}

.ajax-loader {
  margin-left: 20px;
  margin-top: 10px;
}

/*------------------------------------*\
  $PRINT
\*------------------------------------*/
@media print {
  *, *:before, *:after {
    color: #000 !important;
    background: transparent !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
  html,
body {
    margin: 0;
    padding: 0;
    font-size: 13px;
  }
  body {
    margin: 0 auto;
    max-width: inherit !important;
    width: 98% !important;
  }
  @page {
    margin: 1.2cm 0.75cm;
    size: auto;
  }
  /**
   * Hide page elements that aren't
   * relevant for print
   */
  nav,
aside,
video,
form {
    display: none;
  }
  /**
   * Layout, typography and break
   * behaviour styles
   *
   * 1. Three consecutive lines at end of page
   * 2. Two lines at start of new page
   */
  h1,
h2,
h3,
h4,
h5,
h6 {
    page-break-after: avoid;
    font-family: sans-serif;
  }
  h2,
h3,
p {
    orphans: 3; /* [1] */
    widows: 2; /* [2]  */
  }
  ul,
ol,
img,
table,
pre,
blockquote {
    page-break-inside: avoid;
  }
  pre,
blockquote {
    border: 1px solid #212121;
  }
  img {
    margin-bottom: 0.5cm;
    width: auto;
    max-width: 100% !important;
  }
  img:after {
    content: "(" img(alt) ")";
    word-wrap: break-word;
    color: #212121;
    font-style: italic;
    font-size: 13px;
  }
  /**
   * Optimse tables for print
   */
  table {
    border-collapse: collapse;
  }
  table,
table th,
table td {
    border: 1px solid #212121;
  }
  table td,
table th {
    padding: 0.125cm 0.25cm;
  }
  table th {
    font-weight: bold;
  }
  table thead {
    display: table-header-group;
  }
  /**
   * Extend anchors to show detailed
   * information (href) on printout
   */
  a, a:visited {
    color: #06c !important;
  }
  a[href] {
    text-decoration: none;
  }
  a[href]:after {
    content: "(" attr(href) ")";
    word-wrap: break-word;
    color: #212121 !important;
    font-style: italic;
    font-size: 13px;
  }
  a[href^="#"]:after, a[href^=javascript]:after {
    content: "";
  }
  /**
   * Extend abbr to show detailed information
   * from the title-attribute on printout
   */
  abbr {
    border: 0;
  }
  abbr[title]:after {
    content: "(" attr(title) ")";
    font-style: italic;
    font-size: 13px;
  }
  /**
   * Print utility classes
   */
  .print-only {
    display: block !important;
  }
  .hide-print {
    display: none !important;
  }
  .page-break-before {
    page-break-before: always;
  }
  .page-break-after {
    page-break-after: always;
  }
}