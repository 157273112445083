/*------------------------------------*\
  $SIDEBAR
\*------------------------------------*/

.sidebar {
  width: 100%;

  @include mq(mq960) {
    @include span(4.5 of 16);
  }
}


.widget {
  margin: {
    bottom: 25px;
  }
  padding: 25px;

  background: {
    color: #f4f4f4;
  }

  &--enquiry {
    background: {
      color: $brand;
    }

    .widget__title {
      margin: {
        bottom: 15px;
      }
      color: $white;
    }

    ul {
      margin: {
        left: 0;
        bottom: 40px;
      }
    }

    li {
      opacity: 0.75;
      margin: {
        bottom: 10px;
      }
      list-style: none;

      font: {
        size: font-size(15);
      }
      line-height: 1.5;
      color: $white;
    }

    .btn--proceed {
      color: $white;
      background: {
        image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.48 25.92"><path d="M3.78,25.92l8.7-13L3.72,0,0,2.46,7.08,13l-7,10.5Z" fill="#fff"/></svg>');
      }
      border: 1px solid $white;

      @include states {
        color: $white;
        border: 1px solid $white;
      }
    }
  }

  &__title {
    margin: {
      bottom: 5px;
    }

    font: {
      size: font-size(20);
    }
    line-height: 1.2;
    color: #424242;
  }
}
