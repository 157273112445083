/*------------------------------------*\
  $TYPE
\*------------------------------------*/

$h1-size: 25;
$h2-size: 22;
$h3-size: 19;
$h4-size: 16;
$h5-size: 16;
$h6-size: 16;
$intro-size: 20;

/**
 * Headings
 */
h1 {
  font-size: font-size($h1-size);
}

h2 {
  font-size: font-size($h2-size);
}

h3 {
  font-size: font-size($h3-size);
}

h4 {
  font-size: font-size($h4-size);
}

h5 {
  font-size: font-size($h5-size);
}

h6 {
  font-size: font-size($h6-size);
}

/**
 * 1. Make sure headings have proportional line-spacing to avoid a single
 *    heading looking like two.
 */
@include headings {
  margin-bottom: 10px;
  font-family: $heading-font;
  line-height: 1.3; /* 1 */
}

p,
ul,
ol {
  + h2,
  + h3,
  + h4,
  + h5 {
    margin: {
      top: 40px;
    }
  }
}

/**
 * Paragraphs
 */
p {
  margin-bottom: $base-spacing-unit;
  line-height: $base-line-height * 1px;
}

/**
 * The `.intro` class is used to make the introductory test of a page slightly
 * larger than the rest of the page content.
 */
.intro {
  font-size: font-size($intro-size);
}

strong,
b {
  font-weight: bold;
}

small {
  font-size: 75%;
}

em,
i,
cite {
  font-style: italic;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

del {
  text-decoration: line-through;
}

mark {
  background: $yellow;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

address {
  margin-bottom: $base-spacing-unit;
}


/**
 * Links
 */
a {
  color: $link-color;

  @if ($link-underlined == false) {
    text-decoration: none;
  }

  @include states() {
    color: $link-color-hover;

    @if ($link-underlined-hover == false) {
      text-decoration: none;
    } @elseif ($link-underlined-hover == true) {
      text-decoration: underline;
    }
  }
}


/**
 * Blockquotes
 */
q {
  quotes: '\201C''\201D''\2018''\2019';

  &::before {
    content: open-quote;
  }

  &::after {
    content: close-quote;
  }
}

blockquote {
  margin-bottom: 15px;

  p {
    &::before {
      content: '\201C';
    }

    &::after {
      content: '\201D';
    }
  }
}
