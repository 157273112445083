/*------------------------------------*\
  $LISTS
\*------------------------------------*/

ul,
ol {
  margin-bottom: $base-spacing-unit;
  margin-left: 40px;
}

dl {
  margin-bottom: $base-spacing-unit;
}

dt {
  font-weight: bold;
}

dt,
dd {
  margin-bottom: 5px;
}

ul {
  nav & {
    margin: 0;
  }

  li {
    list-style: disc;
  }
}

li {
  margin-bottom: 5px;
  font-size: font-size(16);

  nav & {
    margin: 0;
    list-style: none;
  }

  > ul,
  > ol {
    margin: 5px 0 0 20px;
  }
}
