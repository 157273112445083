/*------------------------------------*\
  $MIXINS
\*------------------------------------*/

/**
 * Link states
 *
 * @include states {
 *   color: blue;
 * }
 */
@mixin states() {
  &:hover,
  &:active,
  &:focus {
    @content;
  }
}


/**
 * Style all headings at once
 *
 * @include headings {
 *   color: red;
 * }
 */
@mixin headings() {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @content;
  }
}


/**
 * Vertically align an element inside its container
 *
 * .thing {  @include vertically-align; }
 */
@mixin vertically-align {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  filter: blur(0); /* Stops the weird text blurring issue in Chrome */
}


/**
 * Horizontally align an element inside its container
 *
 * .thing {  @include horizontally-align; }
 */
@mixin horizontally-align {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  filter: blur(0); /* Stops the weird text blurring issue in Chrome */
}


/**
 * Centrally align an element inside its container
 *
 * .thing {  @include centrally-align; }
 */
@mixin centrally-align {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  filter: blur(0); /* Stops the weird text blurring issue in Chrome */
}


/**
 * Set the position and top, right, bottom, left values
 *
 * .thing { @include position(relative, 0, 1px, 10px, 0); }
 */
@mixin position($position, $top, $right, $bottom, $left) {
  position: $position;
  @if $top { top: $top; }
  @if $right { right: $right; }
  @if $bottom { bottom: $bottom; }
  @if $left { left: $left; }
}
