/*------------------------------------*\
  $TRUMPS
\*------------------------------------*/

.page-content {
  overflow: hidden;

  body:not(.home) & {
    margin: 35px 0;
  }

  > .wrap {
    @include mq($until: mq960) {
      display: flex;
      flex-wrap: wrap;

      .sidebar {
        margin: {
          top: 30px;
        }
        order: 2;
      }
    }
  }
}

.overlay {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;

  background: {
    color: rgba($black, 0.5);
  }

  -webkit-animation: fadeIn 0.3s;
}


.modal {
  @include centrally-align;
  display: none;
  position: fixed;
  z-index: 99999;

  padding: {
    top: 70px;
    right: 50px;
    bottom: 50px;
    left: 50px;
  }

  width: 580px;
  max-width: 90%;

  text-align: center;

  background: {
    color: $white;
  }

  border-radius: 15px;

  -webkit-animation: fadeIn 0.3s;

  &__close {
    position: absolute;
    top: 30px;
    right: 30px;

    padding: 0;

    width: 18px;
    height: 18px;

    text-indent: -9999px;

    background: {
      color: transparent;
      image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90"><title>icon--close</title><path d="M50.14 45l38.8-38.8a3.63 3.63 0 0 0-5.14-5.14L45 39.86 6.2 1.07A3.63 3.63 0 0 0 1.06 6.2L39.86 45 1.07 83.8a3.63 3.63 0 1 0 5.13 5.14L45 50.14l38.8 38.8a3.63 3.63 0 1 0 5.14-5.14z" fill="#7f7f7f"/></svg>');
      size: 18px;
    }
    border: 0;
    outline: 0;

    cursor: pointer;
  }

  &__title {
    font: {
      size: font-size(20);
    }
    color: #424242;
  }

  &__intro {
    font: {
      size: font-size(15);
    }
    line-height: 1.4;
    color: #747474;
  }

  &__social-links {
    margin: {
      top: 25px;
      bottom: 0;
      left: 0;
    }

    li {
      display: inline-block;
      list-style: none;

      text-align: center;

      &:not(:last-child) {
        margin: {
          right: 10px;
        }
      }
    }
  }

  form {
    margin: {
      top: 30px;
      right: auto;
      left: auto;
    }
    width: 320px;
    max-width: 100%;
  }
}


@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}


.is-active {
  display: block;
}


.screen-reader-response {
  display: none;
}

.wpcf7-not-valid-tip {
  margin: 10px 0 0 0 !important;

  font: {
    size: font-size(12);
  }
  color: red;
}

.wpcf7-response-output {
  display: none;
  padding: 15px;
}

.wpcf7-validation-errors {
  color: mix($error-color, $black, 40%);
  background-color: $error-color;
  border: 1px solid mix($error-color, $black, 75%);
}

.ajax-loader {
  margin-left: 20px;
  margin-top: 10px;
}
