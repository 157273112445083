/*------------------------------------*\
  $BOX
\*------------------------------------*/

.box {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 50px 30px;

  border: 3px solid #eee;
  border-radius: 10px;

  transition: transform 0.4s;

  @include states {
    transform: scale(1.01);
  }

  &__icon {
    margin: {
      bottom: 35px;
    }
    width: 75px;
    height: 65px;
  }

  &__title {
    font: {
      size: font-size(20) !important;
    }
    color: #424242;
  }

  &__description {
    margin: {
      bottom: 35px;
    }
    font: {
      size: font-size(15) !important;
    }
    color: #747474;
  }
}

.box--virtual-classroom {
  background-color: $brand;
  color: white;
  max-width: 100%;
  margin: 20px auto 0 auto;
  width: 700px;
  padding: 30px 60px;

  .box__title,
  .box__description {
    color: white;
  }

  .box__description {
    opacity: 0.75;
  }
}
