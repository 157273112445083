/*------------------------------------*\
  $IMAGES
\*------------------------------------*/

img {
  margin-bottom: $base-spacing-unit;
  max-width: 100%;
  font-style: italic;
  vertical-align: middle;
}

/**
 * Google Maps breaks if `max-width: 100%` acts upon it; use their selector
 * to remove the effects.
 *
 * If a `width` and/or `height` attribute have been explicitly defined,
 * let’s not make the image fluid.
 */
.gm-style img,
img[width],
img[height] {
  max-width: none;
}
