/*------------------------------------*\
  $PAGE
\*------------------------------------*/

/**
 * 1. Fonts on OSX will look more consistent with other systems that do not
 *    render text using sub-pixel anti-aliasing.
 */
html {
  overflow-y: scroll;
  min-height: 100%;
  text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale; /* 1 */
  -webkit-font-smoothing: antialiased; /* 1 */
  color: $base-font-color;
  background: {
    color: $base-background-color;
  }
}

body {
  font: {
    family: $body-font;
    size: font-size($base-font-size);
  }
}

.wrap {
  margin: 0 auto;
  width: $wrapper-width;

  @if ($mq-responsive == true) {
    max-width: 90%;

    @include mq(mq960) {
      max-width: 95%;
    }
  }
}
