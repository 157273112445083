/*------------------------------------*\
	$MAIN
\*------------------------------------*/

// vars
@import
  'vars';

// helpers
@import
  'helpers/mixins',
  'helpers/functions',
  'helpers/mq';

// base
@import
  'base/reset',
  'base/box-sizing',
  'base/utilities',
  'base/accessibility',
  'base/type',
  'base/images',
  'base/lists',
  'base/tables',
  'base/forms',
  'base/page';

// layout
@import
  'layout/grid',
  'layout/header',
  'layout/site-navigation',
  'layout/media-window',
  'layout/sidebar',
  'layout/footer';

// modules
@import
  'modules/buttons',
  'modules/messages',
  'modules/embeds',
  'modules/cookies',
  'modules/search',
  'modules/block',
  'modules/box',
  'modules/testimonial',
  'modules/post',
  'modules/article';

// vendor
@import
  'vendor/flickity';

// trumps
@import
  'trumps';

// print
@import
  'print';
