/*------------------------------------*\
  $POSTS
\*------------------------------------*/

.post-summary {
  text-align: left;

  &--tweet {
    padding: 25px;

    background: {
      color: #f4f4f4;
    }
  }

  &__title {
    margin: {
      bottom: 5px;
    }

    font: {
      size: font-size(20);
    }
    line-height: 1.2;
    color: #424242;

    a {
      color: inherit;
      text-decoration: none;

      @include states {
        text-decoration: underline;
      }
    }
  }

  &__meta {
    margin: {
      bottom: 20px;
    }

    font: {
      size: font-size(12.5);
    }
    line-height: 1.4;
    color: #9e9e9e;
  }

  &__excerpt {
    margin: {
      bottom: 20px;
    }
    font: {
      size: font-size(15);
    }
    color: #747474;
  }

  &__link {
    text-decoration: none;

    @include states {
      color: $brand;
      text-decoration: underline;
    }
  }

}
