/*------------------------------------*\
  $BLOCKS
\*------------------------------------*/

.block {
  clear: both;

  padding: 85px 0;

  text-align: center;

  &__header {
    margin: {
      bottom: 70px;
    }
  }

  &__title {
    margin: {
      bottom: 25px;
    }
    padding: {
      left: 5%;
      right: 5%;
    }

    font: {
      size: font-size(25);
    }
    line-height: 1.3;
    color: #424242;
  }

  &__sub-title {
    margin: {
      right: auto;
      bottom: 0;
      left: auto;
    }
    width: 90%;

    font: {
      size: font-size(17.5);
    }
    line-height: 1.5;
    color: #747474;

    @include mq(mq768) {
      width: 61%;
    }
  }

  &__signature-logo {
    padding: 55px 0;

    text-align: center;

    background: {
      color: $white;
    }

    @include mq(mq768) {
      @include span(last 5 of 16);
    }

    img {
      margin: 0;
      width: 60%;
    }
  }
}

.block--signature {
  padding: 40px 0;

  text-align: left;

  background: {
    color: #008c95;
  }

  @include mq(mq768) {
    padding: 85px 0;
  }

  .wrap {
    @extend %cf;
  }

  .block__header {
    margin: {
      bottom: 30px;
    }

    @include mq(mq768) {
      @include span(11 of 16);
      margin: {
        bottom: 0;
      }
      padding: {
        right: 60px;
      }
    }
  }

  .block__title {
    margin: {
      bottom: 10px;
    }
    padding: 0;

    font: {
      size: font-size(22.5);
    }
    color: $white;
  }

  .block__sub-title {
    margin: {
      bottom: 40px;
    }
    width: 100%;
    color: #c2ebee;
  }

  .tertiary-link {
    display: block;
    margin: {
      top: 20px;
    }
    color: #c2ebee;

    @include mq(mq768) {
      display: inline-block;
      margin: {
        left: 30px;
      }
      float: none;
      width: auto;
    }
  }
}

.block--testimonials {
  background: {
    color: #f6f9fc;
  }

  .block__header {
    margin: {
      bottom: 50px;
    }
  }

  .tertiary-link {
    display: block;
    margin: {
      top: 50px;
    }
    color: #87888a;
  }
}

.tertiary-link {
  font: {
    size: font-size(12.5);
  }
}

.flex--services {
  @include mq(mq768) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .page-template-template-courses & {
    margin: {
      top: 60px;
      bottom: 40px;
    }
  }

  .box {
    margin: {
      bottom: 20px;
    }
    width: 100%;

    .btn--pill {
      margin-top: auto;
    }

    @include mq(mq768) {
      margin: {
        bottom: 0;
      }
      width: calc(100% / 4 - 10px);
    }

    @include mq(mq960) {
      width: calc(100% / 4 - 20px);
    }
  }
}

.flex--testimonials {
  @include mq(mq768) {
    display: flex;
    justify-content: space-between;
  }

  .testimonial {
    margin: {
      bottom: 20px;
    }

    @include mq(mq768) {
      margin: {
        bottom: 0;
      }

      width: calc(50% - 20px);
    }
  }
}

.flex--updates {
  @include mq(mq600) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  @include mq(mq768) {
    flex-wrap: nowrap;
  }

  .post-summary {
    &:not(:last-child) {
      margin: {
        bottom: 30px;
      }
    }

    @include mq(mq600) {
      width: calc(50% - 30px);

      &--tweet {
        margin: {
          top: 30px;
        }
        width: 100%;
      }
    }

    @include mq(mq768) {
      margin: {
        bottom: 0;
      }
      width: calc(33.33% - 50px);

      &--tweet {
        margin: {
          top: 0;
        }
      }
    }
  }
}


.flex--courses {
  margin: {
    top: 50px;
  }

  @include mq(mq600) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .box {
    padding: {
      top: 30px;
      bottom: 30px;
    }
    margin: {
      bottom: 30px;
    }

    text-align: left;

    @include mq(mq768) {
      width: calc(50% - 30px);
    }
  }
}
