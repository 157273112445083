/*------------------------------------*\
  $HEADER
\*------------------------------------*/

.site-header {
  height: 75px;

  body:not(.home) & {
    @include mq($until: mq960) {
      border-bottom: 2px solid #f4f4f4;
    }
  }

  @include mq(mq768) {
    padding: 50px 0;

    height: auto;
  }

  .wrap {
    height: 100%;
  }
}

.site-header__logo {
  margin: 0;
  width: 200px;

  @include mq($until: mq768) {
    @include vertically-align;
  }

  @include mq(mq768) {
    width: 322px;
  }
}

.site-actions {
  display: none;

  @include mq($from: mq600, $until: mq768) {
    top: 22px;
    position: absolute;
    right: 100px;
  }

  @include mq($from: mq768, $until: mq960) {
    position: absolute;
    top: 66px;
    right: 100px;
  }

  @include mq(mq600) {
    display: block;
    float: right;
    text-align: right;
  }
}

.site-actions__menu-trigger {
  position: absolute;
  top: 0;
  right: 0;

  height: 73px;
  width: 75px;

  text-indent: -9999px;

  background: {
    color: $white;
    image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 61 43"><path d="M57.5 36h-54a3.5 3.5 0 0 0 0 7h54a3.5 3.5 0 0 0 0-7zm0-18h-54a3.5 3.5 0 0 0 0 7h54a3.5 3.5 0 0 0 0-7zM3.5 7h54a3.5 3.5 0 0 0 0-7h-54a3.5 3.5 0 0 0 0 7z" fill="#ccc"/></svg>');
    size: 21px 15px;
    position: center center;
    repeat: no-repeat;
  }
  border: 0;
  outline: 0;

  cursor: pointer;

  @include mq(mq768) {
    height: 169px;
  }

  @include mq(mq960) {
    display: none;
  }
}

.site-actions__social {
  display: inline-block;
  margin: 0;

  @include mq(mq960) {
    margin: {
      top: 7px;
      right: 20px;
    }
    float: left;
  }

  @include mq(mq1200) {
    margin: {
      right: 30px;
    }
  }

  li {
    display: inline;
    list-style: none;

    font: {
      size: font-size(12.5);
    }
    text-transform: lowercase;

    &:not(:last-child) {
      @include mq(mq960) {
        margin: {
          right: 20px;
        }
      }

      @include mq(mq960) {
        margin: {
          right: 30px;
        }
      }
    }

    span {
      display: none;

      @include mq(mq960) {
        display: inline-block;
      }
    }
  }

  svg {
    display: inline-block;

    position: relative;
    top: 2px;

    margin: {
      right: 10px;
    }

    width: 15.5px;
    height: 12.5px;

    fill: $grey;
  }

  a {
    color: $grey;
    text-decoration: none;
  }
}

.site-actions__email {
  display: none;

  margin: {
    top: 10px;
  }

  font: {
    size: font-size(20);
  }
  color: #595959;
  text-transform: lowercase;
  text-align: right;

  @include mq(mq960) {
    display: block;
  }

  a {
    color: inherit;
    text-decoration: none;

    border-bottom: 1px solid #595959;
    transition: border-color 0.2s;

    @include states {
      border-color: $white;
    }
  }
}
