/*------------------------------------*\
  $TESTIMONIALS
\*------------------------------------*/

.testimonial {
  padding: 40px 30px;
  text-align: left;

  background: {
    color: $white;
  }

  &__title {
    margin: {
      right: 20px;
    }
    float: left;

    font: {
      size: font-size(20);
    }
  }

  &__quote {
    margin: 0;

    p {
      margin-bottom: 0;
      font: {
        size: font-size(15);
      }
      color: #747474;
    }
  }

  &__course {
    display: inline-block;

    position: relative;
    top: -5px;

    margin: {
      bottom: 10px;
    }
    padding: 10px 15px;

    font: {
      size: font-size(10);
    }
    color: #424242;

    background: {
      color: #f3f3f3;
    }

    border-radius: 50px;
  }

  blockquote {
    float: left;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
}
