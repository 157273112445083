/*------------------------------------*\
  $FOOTER
\*------------------------------------*/

.site-footer {
  overflow: hidden;
  position: relative;
  padding: 55px 0;

  background: {
    color: $brand;
  }

  .wrap {
    position: relative;
    z-index: 1;
  }

  &__section {
    &:not(:last-child) {
      margin: {
        bottom: 50px;
      }
    }

    @include mq(mq960) {
      @include span(6 of 16);

      &:last-of-type {
        @include last;
      }
    }

    a {
      color: #c1bdcb;
      text-decoration: none;

      @include states {
        text-decoration: underline;
      }
    }

    p {
      margin: {
        bottom: 5px;
      }

      font: {
        size: font-size(15);
      }
      color: #c1bdcb;

      &:last-of-type {
        margin-bottom: 0;
      }

      a {
        text-decoration: underline;

        @include states {
          text-decoration: none;
        }
      }
    }
  }

  &__title {
    margin: {
      bottom: 30px;
    }
    font: {
      size: font-size(20);
    }
    color: $white;
  }

  &__list {
    margin: {
      left: 0;
      bottom: 20px;
    }
    column-count: 3;

    li {
      margin: {
        bottom: 10px;
      }
      list-style: none;
      font: {
        size: font-size(15);
      }
      color: #c1bdcb;
    }
  }

  > svg {
    position: absolute;
    right: -30px;
    bottom: -60px;
    z-index: 0;

    width: 335px;

    transform: rotate(-10deg);
  }
}

.site-credits {
  padding: 35px 0;

  .wrap {
    overflow: hidden;
  }

  p {
    margin: {
      bottom: 0;
    }
    float: left;
    width: 100%;

    font: {
      size: font-size(12.5);
    }
    line-height: 1.5;
    color: #747474;

    @include mq(mq768) {
      width: auto;
    }

    a {
      color: inherit;
    }
  }

  .btn {
    margin: {
      right: 15px;
      bottom: 15px;
    }
    float: left;

    @include mq(mq768) {
      margin: {
        left: 0px;
        bottom: 0;
      }
      float: right;
    }
  }
}
