/*------------------------------------*\
  $FORMS
\*------------------------------------*/

$input-padding: 0 15px;
$input-border-radius: 15px;
$input-border-color: #e0e0e0;
$input-text-color: $grey;
$input-text-size: $base-font-size;

fieldset {
  border: 0;
}

legend {
  margin-bottom: 5px;
}

label {
  display: block;
  margin-bottom: 5px;
  cursor: pointer;
}

input {
  display: block;
}

/**
 * 1. Remove the default browser styling from elements
 */
input[type="text"],
input[type="email"],
input[type="tel"],
input[type="search"],
input[type="number"],
input[type="date"],
input[type="url"],
input[type="password"],
select,
textarea {
  padding: $input-padding;
  width: 100%;
  height: 45px;
  font: {
    family: $body-font;
    size: font-size($input-text-size);
  }
  color: $input-text-color;
  background-color: $white;
  border: 1px solid $input-border-color;
  border-radius: $input-border-radius;
  -webkit-appearance: none; /* 1 */
  -moz-appearance: none; /* 1 */
}

meter,
progress,
input[type="range"] {
  width: 100%;
}

/**
 * It should be clear that the textarea allows for longer prose, the default
 * is a little too small
 */
textarea {
  display: block;
  padding: {
    top: 15px;
  }
  height: 150px;
}

/**
 * Add an arrow to the select menu since we removed the default one with
 * `appearance: none;`
 */
select {
  background: {
    image: file-url('arrow-down.svg');
    repeat: no-repeat;
    position: right $input-padding center;
  }
}

/**
 * Give checkboxes and radio buttons some breathing space next to labels
 */
input[type="radio"],
input[type="checkbox"] {
  margin-right: 5px;
  display: inline-block;
}

/**
 * Remove inner padding in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has
 * padding (and `textfield` appearance).
 */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

/**
 * 1. Remove the default browser styling from elements
 * 2. Fixed an issue where [type="submit"] gets an additional 2px padding
 *    in Firefox.
 */
input[type="submit"],
button {
  -webkit-appearance: none; /* 1 */
  -moz-appearance: none; /* 1 */

  &::-moz-focus-inner,
  &::-moz-focus-inner {
    border: 0; /* 2 */
    padding: 0; /* 2 */
  }
}


/**
 * `.field` should wrap a label and input
 */
.field {
  margin-bottom: $base-spacing-unit;

  p { margin-bottom: 0; }
}


/**
 * `.field--choice` contains either radio buttons or checkboxes
 */
.field--choice {
  label:last-child {
    margin-bottom: 0;
  }
}


/**
 * `.field--error` modifies the defaults only when the input errors
 */
.field--error {
  input,
  textarea {
    color: mix($error-color, $black, 40%);
    background-color: $error-color;
    border: 1px solid darken($error-color, 50%);
  }
}

.wpcf7-form {
  margin: {
    top: 30px;
  }

  @include mq(mq768) {
    max-width: 70%;
  }

  textarea {
    border-radius: 15px;
  }

  br {
    display: none;
  }

  span {
    margin: {
      bottom: 15px;
    }
    float: left;
    width: 100%;
  }
}
