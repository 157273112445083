/*------------------------------------*\
  $FUNCTIONS
\*------------------------------------*/

$use-asset-pipeline: false !default;
$path: '/images/' !default; // define a path where images are stored
$use-rems: true !default; // Use rems by default


/**
 * Quickly get an em value
 */
@function em($px) {
  @return $px / 16 * 1em;
}


/**
 * Quickly get a rem value
 */
@function rem($px) {
  @return $px / 10 * 1rem;
}


/**
 * Font sizing in rems
 */
@function font-size($font-size, $line-height: false) {
  $rem-size: $font-size / $base-font-size;
  @return if($use-rems, $rem-size * 1rem, $font-size * 1px);
}


/**
 * Output an image URL
 */
@function file-url($file) {
  $url: '';
  @if $use-asset-pipeline {
    $url: image-url($path + $file);
  } @else {
    $url: url($path + $file);
  }
  @return $url;
}


/**
 * Output an optimised SVG data-uri
 */
@function svg-url($svg) {
  // Chunk up string in order to avoid "stack level too deep" error
  $encoded:'';
  $slice: 2000;
  $index: 0;
  $loops: ceil(str-length($svg)/$slice);

  @for $i from 1 through $loops {
    $chunk: str-slice($svg, $index, $index + $slice - 1);

    // Encode (may need a few extra replacements)
    $chunk: str-replace($chunk, '"', "'");
    $chunk: str-replace($chunk, '<', '%3C');
    $chunk: str-replace($chunk, '>', '%3E');
    $chunk: str-replace($chunk, '&', '%26');
    $chunk: str-replace($chunk, '#', '%23');
    $encoded: #{$encoded}#{$chunk};
    $index: $index + $slice;
  }
  @return url("data:image/svg+xml;charset=utf8,#{$encoded}");
}


/**
 * Helper function to replace characters in a string
 */
@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
        str-replace(str-slice($string, $index +
        str-length($search)), $search, $replace);
  }
  @return $string;
}
