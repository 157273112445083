/*------------------------------------*\
  $VARS
\*------------------------------------*/

// page
$wrapper-width: 1200px !default;

// colors
$black: #000;
$white: #fff;
$grey: #a6a6a6;
$light-grey: #ddd;
$dark-grey: #212121;
$yellow: #ff0;
$purple: #3c2667;

$brand: $purple;

$error-color:   #f2dede;
$warning-color: #fcf8e3;
$success-color: #dff0d8;

$facebook: #3b5998;
$linkedin: #007bb6;
$twitter: #00aced;
$pinterest: #cb2027;
$google: #dd4b39;
$youtube: #b00;

$base-font-color: $dark-grey;
$base-background-color: #fff;

// fonts
$body-font: 'Avenir LT W01_65 Medium1475532', sans-serif;
$heading-font: $body-font;

// font sizing
$base-font-size: 16;
$base-line-height: 24;

// link options
$link-color: $brand;
$link-underlined: true;
$link-color-hover: $grey;
$link-underlined-hover: false;

// spacing
$base-spacing-unit: 15px;
