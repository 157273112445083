/*------------------------------------*\
  $UTILITIES
\*------------------------------------*/

.cf,
%cf {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}
