/*------------------------------------*\
  $SEARCH
\*------------------------------------*/

.search-form {
  display: inline-block;
  width: 205px;

  @include mq($from: mq960, $until: mq1200) {
    width: 175px;
  }
}

.screen-reader-text {
  display: none;
}

input.search-field {
  height: 33.5px;

  font: {
    size: font-size(12.5);
  }
  line-height: 33.5px;

  border-radius: 30px;
}

.search-submit {
  display: none;
}

.search-result {

  &:not(:last-child) {
    margin: {
      bottom: 50px;
    }
  }

  &__title {
    font: {
      size: font-size(20);
    }
  }

  a {
    text-decoration: none;
  }

}
