/*------------------------------------*\
  $PRINT
\*------------------------------------*/

$use-rems: false;

@media print {

  * {
    &,
    &:before,
    &:after {
      color: #000 !important;
      background: transparent !important;
      box-shadow: none !important;
      text-shadow: none !important;
    }
  }

  html,
  body {
    margin: 0;
    padding: 0;
    font-size: font-size(13);
  }

  body {
    margin: 0 auto;
    max-width: inherit !important;
    width: 98% !important;
  }

  @page {
    margin: 1.2cm 0.75cm;
    size: auto;
  }


  /**
   * Hide page elements that aren't
   * relevant for print
   */
  nav,
  aside,
  video,
  form {
    display: none;
  }


  /**
   * Layout, typography and break
   * behaviour styles
   *
   * 1. Three consecutive lines at end of page
   * 2. Two lines at start of new page
   */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    page-break-after: avoid;
    font-family: sans-serif;
  }

  h2,
  h3,
  p {
    orphans: 3; /* [1] */
    widows: 2; /* [2]  */
  }

  ul,
  ol,
  img,
  table,
  pre,
  blockquote {
    page-break-inside: avoid;
  }

  pre,
  blockquote {
    border: 1px solid $dark-grey;
  }

  img {
    margin-bottom: 0.5cm;
    width: auto;
    max-width: 100% !important;

    &:after {
      content: '(' img(alt) ')';
      word-wrap: break-word;
      color: $dark-grey;
      font-style: italic;
      font-size: font-size(13);
    }
  }


  /**
   * Optimse tables for print
   */
  table {
    border-collapse: collapse;

    &,
    th,
    td {
      border: 1px solid $dark-grey;
    }

    td,
    th {
      padding: 0.125cm 0.25cm;
    }

    th {
      font-weight: bold;
    }

    thead {
      display: table-header-group;
    }
  }


  /**
   * Extend anchors to show detailed
   * information (href) on printout
   */
  a {
    &,
    &:visited {
      color: #06c !important;
    }

    &[href] {
      text-decoration: none;

      &:after {
        content: '(' attr(href) ')';
        word-wrap: break-word;
        color: $dark-grey !important;
        font-style: italic;
        font-size: font-size(13);
      }
    }

    &[href^="#"]:after,
    &[href^="javascript"]:after {
      content: '';
    }
  }


  /**
   * Extend abbr to show detailed information
   * from the title-attribute on printout
   */
  abbr {
    border: 0;

    &[title]:after {
      content: '(' attr(title) ')';
      font-style: italic;
      font-size: font-size(13);
    }
  }


  /**
   * Print utility classes
   */
  .print-only {
    display: block !important;
  }

  .hide-print {
    display: none !important;
  }

  .page-break-before {
    page-break-before: always;
  }

  .page-break-after {
    page-break-after: always;
  }

}
