/*------------------------------------*\
  $MEDIA WINDOW
\*------------------------------------*/

.media-window__wrapper {
  display: block;
  overflow: hidden;
  margin: 0 auto;
  max-width: 100%;

  @include mq(mq960) {
    width: 1500px;
    max-width: none;
  }
}

.media-window {
  overflow: hidden;

  position: relative;
  z-index: -1;

  float: left;
  width: 100%;
  text-align: center;

  picture {
    overflow: hidden;
    float: left;
    width: 100%;

    @include mq(mq960) {
      @include horizontally-align;
      min-width: 1500px;
      height: 410px;
    }
  }

  a {
    float: left;
    width: 100%;
  }

  img {
    margin: 0;
    width: 100%;
  }
}
