/*------------------------------------*\
  $TABLES
\*------------------------------------*/

$table-th-background-color: $brand;
$table-th-text-color: #424242;
$table-border-color: $light-grey;
$table-striped: false;
$table-stripe-background-color: $light-grey;
$table-stripe-text-color: #000;
$table-equal-width-columns: false;

table {
  margin-bottom: 25px;
  width: 100%;
  border-collapse: collapse;

  @if ($table-equal-width-columns == true) {
    table-layout: fixed;
  }

  @if ($table-striped == true) {
    tr:nth-child(even) {
      background: $table-stripe-background-color;
    }

    color: $table-stripe-text-color;
  }
}

th,
td {
  padding: 20px;
  text-align: left;
  font: {
    family: $body-font;
    size: font-size(16);
  }
  line-height: 1.3;
}

th {
  font: {
    family: $heading-font;
    weight: 600;
    size: font-size(16);
  }
  color: $table-th-text-color;

  border-bottom: 2px solid $table-border-color;
}

tr {
  &:not(:last-of-type) {
    border-bottom: 1px solid $table-border-color;
  }
}

td,
tr {
  color: #747474;
}
